import React from 'react'
import { Link } from 'gatsby-plugin-intl'
import { Segment, Container, Menu, Image, Dropdown } from 'semantic-ui-react'
import { FormattedMessage } from 'gatsby-plugin-intl'

import './Header.css'

import logo_txt from '../../images/mangrove_text_only_white.svg'

const Headers = () => (
  <header className="globalNav">
    <Segment as="nav" vertical>
      <Container textAlign="center">
        <Menu fluid secondary inverted size="massive" className="navRoot">
          <Menu.Menu position="left" className="navSection logo">
            <Menu.Item link as={Link} to="/">
              <Image src={logo_txt} alt="MANGROVE" fluid />
            </Menu.Item>
          </Menu.Menu>

          <Menu.Menu position="right" className="navSection secondary">
            <Menu.Item link as={Link} to="/expertise/" activeClassName="active">
              Expertise
            </Menu.Item>
            <Menu.Item link as={Link} to="/clients/" activeClassName="active">
              Clients
            </Menu.Item>
            <Menu.Item link as={Link} to="/research/" activeClassName="active">
              R&D
            </Menu.Item>
            <Menu.Item link as={Link} to="/company/" activeClassName="active">
              <FormattedMessage id="subtitle-2-footer" />
            </Menu.Item>
            <Menu.Item link as={Link} to="/support/" activeClassName="active">
              Contact
            </Menu.Item>
          </Menu.Menu>

          <Menu.Menu position="right" className="navSection mobile">
            <Dropdown icon="sidebar" item>
              <Dropdown.Menu>
                <Dropdown.Item as={Link} to="/expertise/" text="Expertise" />
                <Dropdown.Item as={Link} to="/clients/" text="Clients" />
                <Dropdown.Item as={Link} to="/company/">
                  <FormattedMessage id="subtitle-2-footer" />
                </Dropdown.Item>
                <Dropdown.Item as={Link} to="/research/" text="R&D" />
                <Dropdown.Item as={Link} to="/support" text="Contact" />
              </Dropdown.Menu>
            </Dropdown>
          </Menu.Menu>
        </Menu>
      </Container>
    </Segment>
  </header>
)

export default Headers
