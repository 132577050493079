import React from 'react'
import { Link } from 'gatsby-plugin-intl'
import {
  Header,
  Container,
  Segment,
  Grid,
  List,
  Icon,
  Image,
} from 'semantic-ui-react'
import { FormattedMessage } from 'gatsby-plugin-intl'

import './Footer.css'

import mangrove_logo from '../../images/mangrove_dark_background.svg'
import apn_logo from '../../images/logo-consulting-partner-aws-mangrove.svg'

const Footer = () => (
  <footer>
    <Segment inverted basic>
      <Container>
        <Grid
          columns={4}
          inverted
          divided
          padded="vertically"
          relaxed="very"
          stackable
          textAlign="center"
          verticalAlign="middle"
        >
          <Grid.Row stretched>
            <Grid.Column>
              <Segment inverted vertical>
                <Container text fluid>
                  <a href="https://mangrove.ai" rel="noopener noreferrer">
                    <Image
                      src={mangrove_logo}
                      alt="MANGROVE"
                      size="small"
                      centered
                    />
                  </a>
                </Container>
              </Segment>
            </Grid.Column>

            <Grid.Column>
              <Segment inverted>
                <Header as="h4" inverted>
                  <FormattedMessage id="subtitle-2-footer" />
                </Header>
                <List link inverted relaxed>
                  <List.Item as={Link} to="/company/">
                    <FormattedMessage id="content-2-footer" />
                  </List.Item>
                  <List.Item as={Link} to="/expertise">
                    Expertise
                  </List.Item>
                </List>
              </Segment>
            </Grid.Column>

            <Grid.Column>
              <Segment inverted>
                <Header as="h4" inverted>
                  <FormattedMessage id="subtitle-3-footer" />
                </Header>
                <List link inverted relaxed>
                  <List.Item
                    as="a"
                    href="https://twitter.com/mangroveai"
                    target="_blank"
                    rel="noopener"
                  >
                    Twitter
                    {` `}
                    <Icon name="external" size="small" />
                  </List.Item>

                  <List.Item
                    as="a"
                    href="https://www.linkedin.com/company/mangrove-ai/"
                    target="_blank"
                    rel="noopener"
                  >
                    LinkedIn
                    {` `}
                    <Icon name="external" size="small" />
                  </List.Item>
                </List>
              </Segment>
            </Grid.Column>
            <Grid.Column>
              <Segment inverted vertical>
                <Container text fluid>
                  <a
                    href="https://aws.amazon.com/partners/find/partnerdetails/?n=Mangrove%20Consulting&id=0010h00001aCXe0AAG"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Image className="APN" src={apn_logo} alt="APN" centered />
                  </a>
                </Container>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Segment>
  </footer>
)

export default Footer
