import React from 'react'
import { Container, Segment } from 'semantic-ui-react'

import Stripes from '../Stripes'

import './PageHeader.css'

export default ({ children, fullWidth, tilted }) => {
  let classes = 'PageHeader'

  if (fullWidth) {
    classes += ' FullWidth'
  }

  if (tilted) {
    classes += ' Tilted'
  }

  return (
    <header className={classes}>
      <Stripes />
      <section>
        {fullWidth ? (
          <Segment inverted vertical basic>
            {children}
          </Segment>
        ) : (
          <Container text={!tilted}>{children}</Container>
        )}
      </section>
    </header>
  )
}
