import React from 'react'
import { Segment, Container, Header, Image } from 'semantic-ui-react'
import Layout from '../components/layout'
import PageHeader from '../components/PageHeader'
import { FormattedMessage } from 'gatsby-plugin-intl'

import labri_logo from '../images/Partners/labri-logo.png'
import anrt_logo from '../images/Partners/anrt-logo.png'
import engie_logo from '../images/Partners/engie-digital-logo.jpg'
import universite_logo from '../images/Partners/universite-bordeaux-logo.jpg'

import './research.css'

const ResearchDevelopmentPrimary = () => (
  <Segment vertical>
    <Container text textAlign="justified">
      <p>
        <FormattedMessage id="content-research-1" />
      </p>
      <p>
        <FormattedMessage id="content-research-2" />
      </p>
      <p>
        <FormattedMessage id="content-research-3" />
      </p>
    </Container>
    <div className="logo client-cards">
      <Image.Group className="logo-group" size="medium">
        <Image spaced src={labri_logo} />
        <Image spaced src={anrt_logo} />
        <Image spaced src={universite_logo} />
        <Image spaced src={engie_logo} />
      </Image.Group>
    </div>
  </Segment>
)

const ResearchDevelopment = () => (
  <Layout>
    <div className="ResearchDevelopment">
      <PageHeader>
        <Header inverted as="h1">
          <FormattedMessage id="title-research" />
        </Header>
        <p>
          <FormattedMessage id="subheader-research" />
        </p>
      </PageHeader>
      <ResearchDevelopmentPrimary />
    </div>
  </Layout>
)

export default ResearchDevelopment
