import React from 'react'

import './Stripes.css'

const Stripes = () => (
  <div className="stripes">
    <span />
    <span />
    <span />
    <span />
    <span />
  </div>
)

export default Stripes
